import { action, computed, observable, reaction, runInAction } from 'mobx'
import { actionAsync, asyncAction } from 'mobx-utils'
import Application from '@/libs/models'
import Web3 from 'web3'
import { async, Subscription, timer } from 'rxjs'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { Zero } from '@/constants'
import { FixedNumber } from '@ethersproject/bignumber'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { localdata } from '@/helpers/local-data'
import { ChainType } from '@/blockchainHandlers'
import { bnHelper } from '@/helpers/bignumber-helper'

export class WalletStore {
  ethereum: any = window.ethereum

  @observable showConnectDialog = false
  app = new Application({ mainnet: true })
  @observable web3: Web3 | null = null
  @observable account = ''
  @observable bnbBalance = Zero
  @observable chainId = Number(process.env.VUE_APP_CHAIN_ID)
  @observable isMetamask = false

  @observable loaded = false

  @observable chainType: ChainType | null = localdata.lastChain

  private _bnbBalanceSubscription: Subscription | undefined

  constructor() {
    reaction(
      () => this.chainType,
      (x) => {
        localdata.lastChain = x as any
      }
    )
  }

  @action.bound changeShowConnectDialog(value: boolean) {
    this.showConnectDialog = value
    if (!value) {
      this.requestingChain = undefined
    }
  }

  @asyncAction *start() {
    try {
      if (this.chainType === 'ftm') {
        this.app.start()
        this.isMetamask = this.app.isMetamask
        this.web3 = this.app.web3
        if (yield this.app.getAddress()) {
          yield this.connect()
        }
      }
    } catch (error) {
      console.error(error)
    }
    this.loaded = true
  }

  @asyncAction *connect() {
    loadingController.increaseRequest()
    try {
      if (this.chainType === 'ftm' && this.account) {
        // connected
        return
      }

      this.account = ''
      const ok = yield this.app.login()
      if (ok) {
        this.web3 = this.app.web3
        this.chainId = +(yield this.web3!.eth.getChainId())
        ;(this.web3 as any).chainId = this.chainId
        switch (this.chainId) {
          case 250:
          case 4002:
          case 1287:
          default:
            this.chainType = 'ftm'
            break
        }
        this.account = yield this.app.getAddress()
        this.ethereum.removeListener('accountsChanged', this.ethereumConfigChanged)
        this.ethereum.removeListener('chainChanged', this.ethereumConfigChanged)
        this.ethereum.once('accountsChanged', this.ethereumConfigChanged)
        this.ethereum.once('chainChanged', this.ethereumConfigChanged)
        this._bnbBalanceSubscription?.unsubscribe()
        this._bnbBalanceSubscription = timer(0, 5000).subscribe(() => {
          this.getBnbBalance()
        })
      }
      this.changeShowConnectDialog(false)
      return ok
    } catch (error: any) {
      error.message && snackController.error(error.message)
      return false
    } finally {
      loadingController.decreaseRequest()
    }
  }

  @observable requestingChain: ChainType | undefined = undefined

  async switchNetwork(chainId: number) {
    if (this.connected) {
      try {
        await this.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(chainId) }],
        })
      } catch (error) {
        if (error.message.includes('wallet_addEthereumChain')) {
          if (chainId === 250) {
            this.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: Web3.utils.toHex(chainId),
                  chainName: 'Fantom Mainnet',
                  nativeCurrency: {
                    name: 'Fantom',
                    symbol: 'FTM',
                    decimals: 18,
                  },
                  rpcUrls: ['https://rpc.ftm.tools/'],
                  blockExplorerUrls: ['https://ftmscan.com/'],
                },
              ],
            })
          } else if (chainId === 4002) {
            this.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: Web3.utils.toHex(chainId),
                  chainName: 'Fantom Testnet',
                  nativeCurrency: {
                    name: 'Fantom',
                    symbol: 'tFTM',
                    decimals: 18,
                  },
                  rpcUrls: ['https://xapi.testnet.fantom.network/lachesis'],
                  blockExplorerUrls: ['https://ftmscan.com/'],
                },
              ],
            })
          } else if (chainId === 1287) {
            this.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: Web3.utils.toHex(chainId),
                  chainName: 'Moonbase Alpha',
                  nativeCurrency: {
                    name: 'Moonbase Alpha',
                    symbol: 'DEV',
                    decimals: 18,
                  },
                  rpcUrls: ['https://moonbase-alpha.public.blastapi.io'],
                  blockExplorerUrls: ['https://moonbase.moonscan.io/'],
                },
              ],
            })
          }
        }
      }
    }
  }
  ethereumConfigChanged = () => {
    window.location.reload()
  }

  // async getFTMInfo() {
  //   try {
  //     const chainId = this.chainId
  //     const ftmBalance = await this.web3?.eth.getBalance(this.account)
  //     return {
  //       decimals: 18,
  //       name: 'FTM',
  //       symbol: 'FTM',
  //       balance: bnHelper.fromDecimals(ftmBalance),
  //     }
  //   } catch (error) {
  //     error.message && snackController.error(error.message)
  //   }
  // }

  @asyncAction *getBnbBalance() {
    const result = yield this.web3?.eth.getBalance(this.account!)
    this.bnbBalance = FixedNumber.from(this.web3?.utils.fromWei(result, 'ether'))
  }

  //#region computed
  @computed get solidityConnected() {
    return this.chainType === 'ftm' && this.connected
  }

  @computed get connected() {
    return !!this.account
  }

  @computed get shortAccount() {
    if (!this.account) return ''
    return this.account.substr(0, 5) + '...' + this.account.substr(this.account.length - 3)
  }

  @computed get isChainIdValid() {
    return this.chainId && this.chainId + '' === Number(process.env.VUE_APP_CHAIN_ID).toString()
  }
}

export const walletStore = new WalletStore()
